import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import DeleteForeverTwoToneIcon from "@material-ui/icons/DeleteForeverTwoTone";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { getPolicy, getToken, apiurl, getClient } from "./utils";
import axios from "axios";
import UpLoadProgressBar from "./UpLoadProgressBar";
import HtmlIframe from "../components/HtmlIframe";
import Switch from '@material-ui/core/Switch';

// const apiurl = process.env.API_URL || "https://tpcrvpapi.com";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#000",
    width: "100%",
  },
}));
let x = 0;
const Html5AssetTabs = (props) => {
  const {
    clientName,
    assetId,
    html5Rounds,
    assetStatus,
    approvedR,
    bannerWidth,
    bannerHeight,
    projectId,
  } = props;

  console.log(clientName);

  const userPolicy = getPolicy();
  const token = getToken();
  const [showLoader, setShowLoader] = useState(false);
  const [uploadPrecentage, setuploadPrecentage] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState(assetStatus);
  const [approvedRound, setApprovedRound] = useState(approvedR);
  const [assetRounds, setAssetRounds] = useState(html5Rounds);

  useEffect(() => {
    x = x + 1;
    if (x < 3) {
      setAssetRounds(html5Rounds);
    }
  });

  // handle Deleting and Closing the current Round
  const [open, setOpen] = useState(false);
  const [addRoundOpen, setAddRoundOpen] = useState(false);

  const handleAddRoundClickOpen = () => {
    setAddRoundOpen(true);
  };

  const handleAddRoundClickClose = () => {
    setAddRoundOpen(false);
  };

  const handleApprove = async (approvedRoundId) => {
    const data = {
      status: "approved",
      approvedround: approvedRoundId,
    };
    try {
      await axios
        .put(`${apiurl}/assets/${assetId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          axios
            .get(`${apiurl}/assets/${assetId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resposne) => {
              setApprovalStatus(resposne.data.status);
              setApprovedRound(resposne.data.approvedround);
            });
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleCancelApprove = async (approvedRoundId) => {
    const data = {
      status: "inreview",
      approvedround: "",
    };
    try {
      await axios
        .put(`${apiurl}/assets/${assetId}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          axios
            .get(`${apiurl}/assets/${assetId}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resposne) => {
              setApprovalStatus(resposne.data.status);
              setApprovedRound(resposne.data.approvedround);
            });
        });
    } catch (error) {
      console.error(error);
    }
  };

  // Adding new HTML Rounds

  const [awsRoundUrl, setAwsRoundUrl] = useState("");

  const handleAddHTMLRound = async (e) => {
    console.log(e);

    const data = {
      htmllink: awsRoundUrl,
      assets: [{ _id: assetId }],
    };

    try {
      await axios
        .post(`${apiurl}/html-version-links`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          console.log(assetId);
          console.log(response.data);
          window.location = `/assets/${projectId}`;
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (e) => {
    console.log(e);
    try {
      await axios
        .delete(`${apiurl}/html-version-links/${e}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          window.location = `/assets/${projectId}`;
          // axios
          //   .get(`${apiurl}/assets/${assetId}`, {
          //     headers: { Authorization: `Bearer ${token}` },
          //   })
          //   .then((resposne) => {
          //     setAssetRounds(resposne.data.rounds);
          //   });
        });
    } catch (error) {
      console.error(error);
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  //Add new rounds
  const handleLogoChange = async (event, expandedAsset) => {
    event.preventDefault();
    setShowLoader(true);

    const allFiles = event.target.files;
    const roundData = new FormData();
    for (let i = 0; i < allFiles.length; i++) {
      roundData.append("files", allFiles[i]);
    }
    roundData.append("ref", "assets");
    roundData.append("refId", expandedAsset);
    roundData.append("field", "rounds");

    await axios({
      method: "POST",
      url: `${apiurl}/upload`,
      data: roundData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (progressEvent) => {
        setuploadPrecentage(
          parseInt(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          )
        );
      },
    }).then((response) => {
      const newRound = {
        id: response.data[0].id,
        name: response.data[0].name,
        width: response.data[0].width,
        height: response.data[0].height,
        mime: response.data[0].mime,
        alternativeText: response.data[0].alternativeText,
        url: response.data[0].url,
      };
      setAssetRounds([...assetRounds, newRound]);
      setShowLoader(false);
    });
  };

  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const reversedRounds = assetRounds.slice(0).reverse();

  const [switchState, setSwitchState] = useState({
    dark: true,
    light: true,
  });

  const handleSwitchChange = (event) => {
    console.log("Event: ", event.target.checked);
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
  };

  return (
    <Box className={classes.root}>
      <Box style={{ overflowX: "scroll" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            style={{
              backgroundColor: "#222",
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Rounds"
          >
            {reversedRounds.map((round, index, title) => {
              let roundTitle = title.length - index;
              return (
                <Tab
                  style={{
                    color: approvedRound === round.id ? "#33cc33" : "#fff",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                  }}
                  label={
                    getClient() === "Hulu"
                      ? "Comp - " + roundTitle
                      : getClient() === "Riot" || clientName === "Riot"
                        ? "Version - " + roundTitle
                        : "Round - " + roundTitle
                  }
                  {...a11yProps(index)}
                  key={index}
                />
              );
            })}
          </Tabs>
        </AppBar>
        {reversedRounds.map((round, index, title) => {
          let roundTitle = title.length - index;

          return (
            <TabPanel value={value} index={index} key={index} style={switchState.dark == true ? { backgroundColor: '#000' } : { backgroundColor: '#fff' }}>
              {showLoader === true ? (
                <Box style={{ width: "350px" }}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    style={{
                      width: "350px",
                      height: "190px",
                      paddingBottom: "15px",
                      paddingTop: "10px",
                    }}
                  />
                  <UpLoadProgressBar uploadPrecentage={uploadPrecentage} />
                </Box>
              ) : (
                <Box>
                    <Box pb={2} display="flex" flexDirection="row" height={50}>
                      <Typography id="continuous-slider" gutterBottom style={switchState.dark == true ? { color: '#fff', paddingTop: '5px' } : { color: '#000', paddingTop: '5px' }}>
                        {switchState.dark == true ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
                      </Typography>
                      <Switch
                        checked={switchState.dark}
                        onChange={handleSwitchChange}
                        name="dark"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        style={{ color: '#ff5100' }}
                      />
                    </Box>
                  <HtmlIframe
                    bannerURL={round.htmllink}
                    bannerWidth={bannerWidth + 5}
                    bannerHeight={bannerHeight + 5}
                  />
                  {approvedRound === round.id ? (
                    <Box style={{ font: "14px", color: "#33cc33" }}>
                      Approved
                    </Box>
                  ) : null}
                </Box>
              )}

              {userPolicy === "trailerparkuser" ? (
                <Fragment>
                  {roundTitle === 1 ? (
                    <Box height="36px" />
                  ) : (
                    <Button onClick={handleClickOpen}>
                      <DeleteForeverTwoToneIcon color="primary" /> Delete Round
                    </Button>
                  )}
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Box
                      style={{
                        backgroundColor: "#222",
                        border: "solid 1px #ffffff",
                        radius: "10px",
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {"Are you sure you want to delete this Round?"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description"></DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={(e) => handleDelete(round.id)}
                          color="primary"
                        >
                          Yes
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus>
                          Cancel
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </Fragment>
              ) : null}
              <Box pb={2} pt={2}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="logo-button"
                  type="file"
                  multiple
                  onChange={(e) => handleLogoChange(e, assetId)}
                />
                {userPolicy === "trailerparkuser" ? (
                  <Fragment>
                    {/* ****************** Adding new round ************************* */}
                    <Dialog
                      open={addRoundOpen}
                      onClose={handleAddRoundClickClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <Box
                        style={{
                          backgroundColor: "#222",
                          border: "solid 1px #ffffff",
                          radius: "10px",
                        }}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Adding new Round"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <TextField
                              id="standard-basic"
                              label="Add Round AWS URL"
                              onChange={(e) => setAwsRoundUrl(e.target.value)}
                              style={{
                                width: "500px",
                              }}
                            />
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={(e) => handleAddHTMLRound(round.id)}
                            color="primary"
                          >
                            Add
                          </Button>
                          <Button
                            onClick={handleAddRoundClickClose}
                            color="primary"
                            autoFocus
                          >
                            Cancel
                          </Button>
                        </DialogActions>
                      </Box>
                    </Dialog>
                    <Button
                      variant="outlined"
                      color="primary"
                      component="span"
                      onClick={handleAddRoundClickOpen}
                    >
                      Add Rounds
                    </Button>

                    <Box style={switchState.dark == true ? { color: "#fff", padding: "10px", fontSize: "16px" } : { color: "#000", padding: "10px", fontSize: "16px" }}>
                      <span style={{ color: '#FF0000' }}>*</span> Make sure all tabs are collapsed before you add new round
                    </Box>
                  </Fragment>
                ) : (
                  <Fragment>
                    {approvalStatus !== "approved" ? (
                      <Fragment>
                        <Button
                          onClick={() => handleApprove(round.id)}
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          {getClient() === "Hulu"
                            ? "Approve This COMP"
                            : "Approve This Round"}
                        </Button>
                        <Typography
                          style={{ padding: "10px", fontSize: "12px" }}
                        >
                            <span style={switchState.dark == true ? { color: "#FF0000" } : { color: "#000" }}>*</span> Please
                          make sure that the round you want to approve is
                          selected before you click on approve round.
                        </Typography>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Button
                          onClick={() => handleCancelApprove(round.id)}
                          variant="outlined"
                          color="primary"
                          component="span"
                        >
                          Cancel Approval
                        </Button>
                        <Typography
                          style={{ padding: "10px", fontSize: "12px" }}
                        >
                              <span style={switchState.dark == true ? { color: "#FF0000" } : { color: "#000" }}>*</span> Please
                          make sure that the round you want to approve is
                          selected before you click on approve round.
                        </Typography>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export default Html5AssetTabs;
